import {
  configureStore,
  Middleware,
  Dispatch,
  AnyAction,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import reducer from './reducer';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { useNavigate } from 'react-router-dom';

const persistConfig = {
  key: 'AppReduxState',
  storage,
  whitelist: [
    'interactive',
    'userProfile',
    'projects',
    'rubricsList',
    'createProject',
    'challengeList',
  ],
};

const logger = createLogger({ collapsed: true }) as Middleware<{}, any, Dispatch<AnyAction>>;

let middleware: Middleware<{}, any, Dispatch<AnyAction>>[] = [];
const appReducer = combineReducers(reducer);
const persistedReducer = persistReducer(persistConfig, appReducer);

const rootReducer = (state: any, action: any) => {
  if (action.type === 'identity/logout') {
    console.log('CLEAR ALL REDUX STATE');
    localStorage.removeItem('persist:AppReduxState');

    return persistedReducer(undefined, action);
  }
  return persistedReducer(state, action);
};

if (process.env.NODE_ENV === `development`) {
  middleware.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: {
        extraArgument: {
          // navigate: useNavigate,
        },
      },
    }).concat(middleware),
});

export const persistor = persistStore(store);

// Определите тип extraArgument
interface ExtraArgument {
  // navigate: (path: string) => void;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  ExtraArgument,
  Action<string>
>;

export default store;
