import React, { useMemo, useRef, useState } from 'react';
import Spacing from '@component/spacing/spacing';

import styles from './popup.module.scss';

interface PopupProps {
  before?: JSX.Element | string | number;
  btnText?: string;
  children?: React.ReactNode;
  hidden?: boolean;
  positionHorizontal?: 'left' | 'center' | 'right';
  positionVertical?: 'top' | 'top48' | 'bottom' | 'center';
  showTime?: number; // sec
  text?: JSX.Element | string | number;
  title?: string;
  variant?: 'dark' | 'light';
  width?: 'auto' | 'fill';
  align?: 'left' | 'center' | 'right';
  className?: string;
  onClick?: () => void;
  callback?: () => void;
}

const PopupComponent = (props: PopupProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const timerRef = useRef<any>();

  const className = useMemo(
    () => `${styles.container} ${props.className ? props.className : ''}`,
    [props.className],
  );

  useMemo(() => {
    if (props.title || props.text || props.children) {
      setIsVisible(true);
    }

    if (props.showTime && props.showTime > 0) {
      timerRef.current = setTimeout(() => {
        setIsVisible(false);
        props.callback && props.callback();
      }, props.showTime * 1000);
    }

    return () => clearTimeout(timerRef.current);
  }, [props, timerRef]);

  return (
    <div
      className={className}
      data-theme={props.variant ?? 'dark'}
      data-position-vertical={props.positionVertical ?? 'bottom'}
      data-position-horizontal={props.positionHorizontal ?? 'center'}
      data-width={props.width ?? 'auto'}
      data-align={props.align ?? 'left'}
      hidden={!isVisible}
    >
      {props.before && <div className={styles.before}>{props.before}</div>}
      <div className={styles.content}>
        {props.title && <div className={styles.title}>{props.title}</div>}
        {props.title && props.text && <Spacing size="h8" />}
        {props.text && <div className={styles.text}>{props.text}</div>}
        {(props.text || props.title) && props.btnText && <Spacing size="h8" />}
        {props.btnText && (
          <div onClick={props.onClick} className={styles.btn}>
            {props.btnText}
          </div>
        )}
        {props.children && <div className={styles.children}>{props.children}</div>}
      </div>
    </div>
  );
};

export default React.memo(PopupComponent);
