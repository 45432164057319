import { ApiResponse, CreateResponse } from '../../../types';
import { apiClients } from '../../index';
import { ICreateProjectRequest } from '../../../models/create-project-request';
import { IEditProjectRequest } from '../../../models/edit-project-request';

export const projects = {
  post: async (request: ICreateProjectRequest) => {
    const formData = new FormData();

    Object.entries(request).forEach((item) => {
      if (item[0] === 'images') {
        for (let i = 0; i < item[1].length; i++) {
          formData.append(item[0], item[1][i]);
        }
      } else {
        formData.append(item[0], item[1]);
      }
    });

    return await apiClients.default.post<CreateResponse>('projects', formData);
  },
  v2: {
    post: async (request: ICreateProjectRequest) => {
      const formData = new FormData();

      Object.entries(request).forEach((item) => {
        if (item[0] === 'images') {
          for (let i = 0; i < item[1].length; i++) {
            formData.append(item[0], item[1][i]);
          }
        } else {
          formData.append(item[0], item[1]);
        }
      });

      return await apiClients.default.post<CreateResponse>('projects/v2', formData);
    },
    put: async (request: IEditProjectRequest) => {
      const formData = new FormData();

      Object.entries(request).forEach((item) => {
        if (!item[1]) {
          return;
        }
        if (item[0] === 'images') {
          for (let i = 0; i < item[1].length; i++) {
            formData.append(item[0], item[1][i]);
          }
        } else if (
          item[0] === 'imagesProjectMediaData' ||
          item[0] === 'skillTypesIds' ||
          item[0] === 'videoProjectMediaData'
        ) {
          item[1].forEach((k: any) => formData.append(item[0], k));
        } else {
          formData.append(item[0], item[1]);
        }
      });

      return await apiClients.default.put<CreateResponse>('projects', formData);
    },
  },
  PROJECT_ID: {
    likes: {
      post: async (projectId?: number) => {
        return await apiClients.default.post(`projects/${projectId}/likes`);
      },
    },
    complaint: {
      post: async ({
        projectId,
        formData,
      }: {
        projectId: number;
        formData?: FormData;
      }): Promise<ApiResponse> => {
        return await apiClients.default.post<ApiResponse>(
          `projects/${projectId}/complaints`,
          formData,
        );
      },
    },
  },
  delete: async (projectId?: number) => {
    return await apiClients.default.delete(`projects/${projectId}`);
  },
};
