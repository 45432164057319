import { ApiResponse } from '../../../types';
import { apiClients } from '../..';
import { IQuizAnswer, IQuizInfo, IQuizQuestion, IQuizResult } from '../../../models/quiz';

export interface IQuizInfoResponse extends ApiResponse, IQuizInfo {}
export interface IQuizResultResponse extends ApiResponse, IQuizResult {}
export interface IQuizQuestionsResponse extends ApiResponse, IQuizQuestion {}
export interface IQuizSubmitResponse extends ApiResponse {
  solutionId: number;
}

export interface IQuizSubmitRequest {
  isFinal: boolean;
  answers?: IQuizAnswer[];
}

export const quiz = {
  info: {
    get: async (challengeId: number) =>
      await apiClients.default.get<IQuizInfoResponse>('v1/quiz/info', {
        params: { challengeId },
      }),
  },
  questions: {
    get: async (quizId: number) =>
      await apiClients.default.get<IQuizQuestionsResponse>(`v1/quiz/${quizId}/questions`),
  },
  start: {
    post: async (quizId: number) =>
      await apiClients.default.post<ApiResponse>(`v1/quiz/${quizId}/start`),
  },
  submit: {
    post: async ({ quizId, request }: { quizId: number; request: IQuizSubmitRequest }) =>
      await apiClients.default.post<IQuizSubmitResponse>(`v1/quiz/${quizId}/submit`, request),
  },
  result: {
    get: async (solutionId: number) =>
      await apiClients.default.get<IQuizResultResponse>('v1/quiz/result', {
        params: { solutionId },
      }),
  },
};
