import { ApiResponse } from '../../../types';
import { apiClients } from '../../';

interface ResetPasswordRequest {
  contact: string;
}

interface ResetPasswordConfirmRequest {
  contact: string;
  code: string;
  password?: string;
}

interface ResetPasswordConfirmResponse extends ApiResponse {
  code?: string;
}

export const identity = {
  resetPassword: {
    put: (request: ResetPasswordRequest) => {
      return apiClients.default.put<ApiResponse>('v2/identity/reset-password', request);
    },
    confirm: {
      put: (request: ResetPasswordConfirmRequest) => {
        return apiClients.default.put<ResetPasswordConfirmResponse>(
          'v2/identity/reset-password/confirm',
          request
        );
      },
    },
  },
};
