import { apiClients } from '../../index';
import { ApiResponse } from '../../../types';

export const comments = {
  COMMENTS_ID: {
    likes: {
      post: async (commentsId: number) => {
        return await apiClients.default.post<ApiResponse>(`comments/${commentsId}/likes`);
      },
    },
    delete: async (commentsId: number) => {
      return await apiClients.default.delete<ApiResponse>(`comments/${commentsId}/`);
    },
    complaints: {
      post: async (commentsId: number) => {
        return await apiClients.default.post<ApiResponse>(`comments/${commentsId}/complaints`);
      },
    },
  },
};
