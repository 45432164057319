import { apiClients } from '../../';
import { ApiResponse } from '../../../types';
import { ERole } from '../../../enums';

export interface IInviteResponse extends ApiResponse {
  role: ERole;
}

export const invite = {
  get: (code: string) => {
    return apiClients.default.get<IInviteResponse>(`v1/invite/${code}`);
  },
};
