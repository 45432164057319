import auth from '../http/auth';
import { IHttpRequestConfig } from '../http/http-client';
import { DEFAULT_LOCALE } from '../constants/constants';
import appConfig from '../app-config';

export const applyHttpHeadersConfig = (config?: IHttpRequestConfig) => {
  const cfg: IHttpRequestConfig = { ...config };
  cfg.headers = {
    ...cfg.headers,
    'Authorization': `Bearer ${auth.access_token}`,
    'x-client-id':
      (process.env.NODE_ENV !== 'production' && appConfig.scheme) || localStorage.getItem('scheme'),
  };

  if (!cfg.headers['x-language']) {
    cfg.headers['x-language'] = localStorage.getItem('locale') || DEFAULT_LOCALE;
  }

  return cfg;
};
