import { IChallengeItem } from '../../http/models/challenge-item';
import { IProjectItem } from '../../http/models/project-item';
import { IUserItem } from '../../http/models/user-item';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { EProjectSortingOrder } from '../../http/enums';
import { uniqBy } from 'lodash';
import { ProjectListRequest } from '../../http/api/v1/project/project';
import { addItems as projectAddItems } from './projects';
import { v1 } from '@api/v1';

export type TSearchType = 'challenge' | 'project' | 'user';

interface IGlobalSearch {
  error?: string;
  items: (IChallengeItem | IProjectItem | IUserItem)[] | undefined;
  loading: boolean;
  page: number;
  searchPosition: number;
  searchString: string;
  take: number;
  total: number;
  sortingOrder?: string;
  from?: string;
}

const initialState: IGlobalSearch = {
  items: undefined,
  loading: false,
  page: 1,
  searchPosition: 0,
  searchString: '',
  take: 20,
  total: 0,
};

const slice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setFrom: (state, action: PayloadAction<string>) => {
      state.from = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTake: (state, action: PayloadAction<number>) => {
      state.take = action.payload;
    },
    setPosition: (state, action: PayloadAction<number>) => {
      state.searchPosition = action.payload;
    },
    setItems: (
      state,
      action: PayloadAction<(IChallengeItem | IProjectItem | IUserItem)[] | undefined>,
    ) => {
      state.items = uniqBy(
        action.payload?.map((i: any) => ({ ...i, nickname: i.userName })),
        ({ id }) => id,
      );
    },
    addItems: (state, action: PayloadAction<(IChallengeItem | IProjectItem | IUserItem)[]>) => {
      state.items?.push(...action.payload.map((i: any) => ({ ...i, nickname: i.userName })));

      state.items = uniqBy(state.items, ({ id }) => id);
    },
    reset: () => initialState,
    resetLight: (state) => {
      state.items = undefined;
      state.loading = false;
      state.page = 1;
      state.searchPosition = 0;
      state.searchString = '';
      state.take = 20;
      state.total = 0;
    },
  },
});

const {
  setFrom,
  setPosition,
  setError,
  setLoading,
  setTotal,
  setPage,
  setTake,
  setItems,
  addItems,
  reset,
  setSearchString,
  resetLight,
} = slice.actions;

const globalSearch = {
  resetLight,
  setFrom,
  setPosition,
  reset,
  setPage,
  setTake,
  setSearchString,
  setError,
  setLoading,
  setTotal,
  setItems,
  addItems,
  selectTotal: (state: RootState) => state.globalSearch.total,
  selectFrom: (state: RootState) => state.globalSearch.from,
  selectSearchPosition: (state: RootState) => state.globalSearch.searchPosition,
  selectLoading: (state: RootState) => state.globalSearch.loading,
  selectItems: (state: RootState) => state.globalSearch.items,
  selectPage: (state: RootState) => state.globalSearch.page,
  selectSearchString: (state: RootState) => state.globalSearch.searchString,
  loadData:
    (loadDataType: TSearchType, loadmore?: boolean): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();

      if (!state.globalSearch.searchString.length) {
        return;
      }

      const responseObject = {
        search: state.globalSearch.searchString,
        skills: { fetch: true },
        medias: { fetch: true },
        page: loadmore ? state.globalSearch.page + 1 : state.globalSearch.page,
        take: loadDataType === 'project' ? 10 : 20,
      };

      if (loadDataType === 'project') {
        (responseObject as ProjectListRequest).sortingOrder = EProjectSortingOrder.MostLiked;
      }

      try {
        dispatch(setLoading(true));
        dispatch(setError(''));

        const response = await v1[loadDataType].get(responseObject);

        if (response.errorCode) {
          dispatch(setError(response.errorMsg));
          return;
        }
        if (loadmore) {
          dispatch(setPage(state.globalSearch.page + 1));
          dispatch(addItems(response.items || []));
        } else {
          dispatch(setItems(response.items || []));
        }
        // для поддержания проектов в одном месте
        if (loadDataType === 'project') {
          dispatch(projectAddItems(response.items as IProjectItem[]));
        }
        dispatch(setTotal(response.total ?? 0));
      } finally {
        dispatch(setLoading(false));
      }
    },
};

export const globalSearchReducer = slice.reducer;
export default globalSearch;
