import { ApiResponse, ListApiResponse } from '../../../types';
import { apiClients } from '../../';
import { EChallengeSortingType } from '../../../enums';
import { Paging } from '../../../../types';
import { IAttributesFilter } from '../../../filters/attributes-filter';
import { IRubricsFilter } from '../../../filters/rubrics-filter';
import { ISkillsFilter } from '../../../filters/skills-filter';
import { IChallengeItem } from '../../../models/challenge-item';

interface ChallengeListRequest extends Paging {
  id?: number;
  liked?: boolean;
  rubrics?: IRubricsFilter;
  attributes?: IAttributesFilter;
  skills?: ISkillsFilter;
  sortingType?: EChallengeSortingType;
}

export interface ChallengeResponse extends IChallengeItem, ApiResponse {}
export interface ChallengeListResponse extends ListApiResponse<IChallengeItem> {}

export const challenge = {
  get: async (request: ChallengeListRequest) => {
    return apiClients.default.get<ChallengeListResponse>(`v1/challenge`, { params: request });
  },
  published: {
    get: async (request: ChallengeListRequest) =>
      apiClients.default.get<ChallengeListResponse>(`v1/challenge`, {
        params: {
          ...request,
          state: 'Published',
        },
      }),
  },
  CHALLENGE_ID: {
    get: async (challengeId: number) => {
      return apiClients.default.get<ChallengeResponse>(`v1/challenge/${challengeId}`);
    },
  },
};
