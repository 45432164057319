import { ApiResponse } from '../../../types';
import { apiClients } from '../../index';
import { ESkillState } from '../../../enums';

interface RubricItem {
  id?: string;
  order?: number;
  name?: string;
}

export interface SkillTypeListItem {
  id?: string;
  order?: number;
  name?: string;
  icon?: string;
  challengeCount?: number;
  rubrics?: RubricItem[];
}

export interface SkillTypeDetails extends ApiResponse {
  id?: string;
  name?: string;
  icon?: string;
  descriptionHTML?: string;
  challengeCount?: number;
  rubrics?: RubricItem[];
  currentExperience?: number;
  totalExperience?: number;
  skillLevelExperience?: number;
  skillLevel?: number;
  skillLevelDelta?: number;
  state?: ESkillState;
  stateName?: string;
}

export const skillTypes = {
  get: async (id?: string) => {
    const response = await apiClients.default.get<SkillTypeDetails>(`SkillTypes/${id}`);
    return response;
  },
};
