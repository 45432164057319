import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { isEmpty } from 'lodash';
import storage from '..';
import { IProjectItem } from '../../http/models/project-item';
import { v1 } from '@api/v1';

interface IEventProjectListState {
  loading: boolean;
  error?: string;
  ids: number[];
  total: number;
}

const initialState: { [p: string]: IEventProjectListState } = {};

const createState = (): IEventProjectListState => ({
  loading: false,
  ids: [],
  total: 0,
});

const slice = createSlice({
  name: 'eventProjectList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ id: number; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].error = action.payload.error;
    },
    setIds: (state, action: PayloadAction<{ id: number; ids?: number[] }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].ids = action.payload.ids ?? [];
    },
    setTotal: (state, action: PayloadAction<{ id: number; total?: number }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].total = action.payload.total ?? 0;
    },
  },
});

const { setLoading, setError, setIds, setTotal } = slice.actions;

const getEventIds = async (id: number) => {
  const response = await v1.event.id.get(id);

  if (response.errorCode) {
    return [id];
  }

  return response.errorCode
    ? [id]
    : [...(response?.events?.map((event: any) => event.id) || []), id];
};

const eventProjectList = {
  setLoading: (id: number, loading: boolean) => setLoading({ id, loading }),
  setError: (id: number, error?: string) => setError({ id, error }),
  setTotal: (id: number, total?: number) => setTotal({ id, total }),
  selectError: (id: number) => (state: RootState) => state.eventProjectList[id]?.error,
  selectLoading: (id: number) => (state: RootState) => state.eventProjectList[id]?.loading ?? true,
  selectItems: (id: number) => (state: RootState) =>
    state.eventProjectList[id]?.ids.map((id) => state.projects[id]).filter(Boolean),
  selectTotal: (id: number) => (state: RootState) => state.eventProjectList[id]?.total ?? 0,
  loadData:
    (id: number, page?: number): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setError({ id }));
        const eventIds = await getEventIds(id);
        const response = await v1.project.get({
          eventId: eventIds,
          medias: { fetch: true },
          skills: { fetch: true },
          take: 25,
          page: page ?? 1,
        });
        if (response.errorCode) {
          dispatch(setError({ id, error: response.errorMsg }));
          return;
        }
        if (response && !isEmpty(response.items)) {
          dispatch(storage.projects.addItems(response.items));
          dispatch(
            setIds({
              id,
              ids: (response.items as IProjectItem[]).map((item: IProjectItem) => item.id),
            }),
          );
          dispatch(setTotal({ id, total: response.total }));
        }
      } finally {
        dispatch(setLoading({ id, loading: false }));
      }
    },
};

export const eventProjectListReducer = slice.reducer;
export default eventProjectList;
