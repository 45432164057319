import { ApiResponse, ListApiResponse } from '@http/types';
import { apiClients } from '../../';
import { ILocation } from '@model/location';

export interface IOrganizationResponse {
  code: string;
  style: string;
  name: string;
  errorCode: 0;
  errorMsg: string;
}

export interface IOrganizationModuleOption {
  id: number;
  name: string;
  type: number;
  value: string | number | boolean;
  defaultValue: string | number | boolean;
}

export interface IOrganizationModuleItem {
  id: number;
  moduleName: string;
  moduleDisabled: boolean;
  moduleEnabled: boolean;
  options?: IOrganizationModuleOption[];
  normalizedOptions?: Record<string, IOrganizationModuleOption>;
}

export interface IOrganizationDocument {
  publicationRules: string;
  licensingPolicy: string;
  externalLibraries: string;
}

export interface IOrganizationModuleResponse extends ListApiResponse<IOrganizationModuleItem> {}

export interface ILocationResponse extends ListApiResponse<ILocation>, ApiResponse {}

export interface IDocumentResponse extends IOrganizationDocument, ApiResponse {}

export const organization = {
  scheme: {
    get: async () => await apiClients.default.get<IOrganizationResponse>('v1/organization/scheme'),
  },
  moduleOption: {
    get: async () =>
      await apiClients.default.get<IOrganizationModuleResponse>('v1/organization/module/option'),
  },
  location: {
    get: () => {
      return apiClients.default.get<ILocationResponse>('v1/organization/location', {
        params: { take: 50 },
      });
    },
  },
  document: {
    get: async () => await apiClients.default.get<IDocumentResponse>('/v1/organization/document'),
  },
};
