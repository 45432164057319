import { useEffect, useRef } from 'react';

export const useSwipeDownToRefresh = () => {
  const touchStartYRef = useRef<number | null>(null);

  const handleTouchStart = (event: any) => {
    touchStartYRef.current = event.touches[0].clientY;
  };

  const handleTouchEnd = (event: any) => {
    const touchEndY = event.changedTouches[0].clientY;
    let deltaY;

    if (touchStartYRef.current) {
      deltaY = touchEndY - touchStartYRef.current;

      if (deltaY > 100 && touchStartYRef.current < 200) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);
};
