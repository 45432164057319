import { apiClients } from '../../index';
import { ListApiResponse } from '../../../types';
import { IUserItem } from '../../../models/user-item';

export interface AchievementUserListResponse extends ListApiResponse<IUserItem> {}
export interface AchievementUserItem extends IUserItem {}

export const achievementUserList = {
  get: (achievementId: string) => {
    return apiClients.default.get<AchievementUserListResponse>(
      `v1/achievement/${achievementId}/user`,
    );
  },
};
