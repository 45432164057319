import { IProjectItem } from './../../http/models/project-item';
import { union } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { batch } from 'react-redux';
import { EProjectSortingOrder } from '../../http/enums';
import storage from '..';
import { IProjectListRequest } from '../../http/models/project-list-request';
import { v2 } from '@api/v2';

export interface IProjectRequestProps {
  position?: number;
  take?: number;
  refresh?: boolean;
  direction?: 'next' | 'prev';
  sortingOrder?: EProjectSortingOrder;
  isUserSubscription?: boolean;
}

interface IFeedState {
  error?: string;
  ids: number[];
  userIds: number[];
  loading: boolean;
  position: number;
  positionUserItems: number;
  refresh: boolean;
  totalItemsCount: number;
  totalUserItemsCount: number;
  scrollPosition: number;
}

const initialState: IFeedState = {
  ids: [],
  userIds: [],
  loading: false,
  position: 0,
  positionUserItems: 0,
  refresh: true,
  totalItemsCount: 0,
  totalUserItemsCount: 0,
  scrollPosition: 0,
};

const slice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setRefresh: (state, action: PayloadAction<boolean>) => {
      state.refresh = action.payload;
      if (action.payload) {
        state.ids = [];
        state.userIds = [];
        state.position = 0;
        state.positionUserItems = 0;
      }
    },
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
    setPosition: (state, action: PayloadAction<number>) => {
      state.position = action.payload;
    },
    setPositionUserItems: (state, action: PayloadAction<number>) => {
      state.positionUserItems = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload;
    },
    setTotalUserItemsCount: (state, action: PayloadAction<number>) => {
      state.totalUserItemsCount = action.payload;
    },
    addIds: (state, action: PayloadAction<number[]>) => {
      state.ids = union(state.ids, action.payload);
    },
    addUserIds: (state, action: PayloadAction<number[]>) => {
      state.userIds = union(state.userIds, action.payload);
    },
    deleteId: (state, action: PayloadAction<number>) => {
      state.ids = state.ids.filter((id) => id !== action.payload);
    },
    deleteUserId: (state, action: PayloadAction<number>) => {
      state.userIds = state.userIds.filter((id) => id !== action.payload);
    },
  },
});

const {
  addIds,
  addUserIds,
  deleteId,
  setError,
  setLoading,
  setPosition,
  setScrollPosition,
  setPositionUserItems,
  setRefresh,
  setTotalItemsCount,
  setTotalUserItemsCount,
} = slice.actions;

const feed = {
  deleteId,
  setError,
  setLoading,
  setScrollPosition,
  setPosition,
  setPositionUserItems,
  setRefresh,
  selectRefresh: (state: RootState) => state.feed.refresh,
  selectLoading: (state: RootState) => state.feed.loading,
  selectScrollPosition: (state: RootState) => state.feed.scrollPosition,
  selectPosition: (state: RootState) => state.feed.position,
  selectPositionUserItems: (state: RootState) => state.feed.positionUserItems,
  selectError: (state: RootState) => state.feed.error,
  selectItems: (state: RootState) => state.feed.ids.map((id) => state.projects[id]).filter(Boolean),
  selectUserItems: (state: RootState) =>
    state.feed.userIds.map((id) => state.projects[id]).filter(Boolean),
  selectTotalItemsCount: (state: RootState) => state.feed.totalItemsCount,
  selectTotalUserItemsCount: (state: RootState) => state.feed.totalUserItemsCount,
  loadProjects:
    ({
      position = 0,
      take = 10,
      sortingOrder,
      isUserSubscription = false,
      refresh = false,
    }: IProjectRequestProps): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();

      batch(() => {
        dispatch(setLoading(true));
        dispatch(setError(''));
      });

      let requestParams: IProjectListRequest = {
        position: position,
        take: take,
        medias: { fetch: true },
        direction: 'next',
      };

      if (isUserSubscription) {
        requestParams.author = { isFollowed: true };
      } else {
        requestParams = {
          ...requestParams,
          sortingOrder,
          refresh: refresh,
          recommended: true,
        };
      }

      try {
        const response = await v2.project.get(requestParams);

        if (response.errorCode) {
          dispatch(setError(response.errorMsg));
          return;
        }

        batch(() => {
          dispatch(setRefresh(false));
          dispatch(storage.projects.addItems(response.items));

          if (response) {
            if (isUserSubscription) {
              dispatch(
                addUserIds((response.items as IProjectItem[]).map((item: IProjectItem) => item.id)),
              );
              dispatch(setTotalUserItemsCount(response.total));
              dispatch(setPositionUserItems(state.feed.positionUserItems + take));
            } else {
              dispatch(
                addIds((response.items as IProjectItem[]).map((item: IProjectItem) => item.id)),
              );
              dispatch(setTotalItemsCount(response.total));
              dispatch(setPosition(state.feed.position + take));
            }
          }
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
};

export const feedReducer = slice.reducer;
export default feed;
