import { apiClients } from '../../index';
import { ApiResponse } from '../../../types';
import { IChallengeItem } from '../../../models/challenge-item';

export const challengesLikes = {
  get: async () => {
    return await apiClients.default.get<IChallengeItem[]>(`Challenges/Likes`);
  },
  post: async (id: number) => {
    const response = await apiClients.default.post<ApiResponse>(`Challenges/${id}/Likes`);
    return response;
  },
};
