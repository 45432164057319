import { apiClients } from '../../';
import { TSocial } from '../../../../types/social';
import { ApiResponse } from '@http/types';

export interface RegisterUserBySocialRequest {
  provider: TSocial;
  token: string;
  verifier?: string; // uuid from vk

  username?: string;
  birthYear?: number;
  referralCode?: string;
}

export interface AttachUserBySocialRequest {
  provider: TSocial;
  token: string;
  verifier: string; // uuid from vk
}

export interface DetachUserBySocialRequest {
  provider: TSocial;
}

interface RegisterUserBySocialResponse extends ApiResponse {
  access_token: string;
  refresh_token: string;
}

export const external = {
  registration: {
    post: (request: RegisterUserBySocialRequest) => {
      return apiClients.default.post<RegisterUserBySocialResponse>(
        'v1/external/registration',
        request,
      );
    },
  },
  user: {
    post: (request: AttachUserBySocialRequest) => {
      return apiClients.default.post<ApiResponse>('v1/external/user', request);
    },
    delete: (request: DetachUserBySocialRequest) => {
      return apiClients.default.delete<ApiResponse>('v1/external/user', { data: request });
    },
  },
};
