import { apiClients } from '../../index';
import { ApiResponse } from '../../../types';

export interface UserAvatarItem {
  id?: number;
  url?: string;
  isAdmin?: boolean;
}

type UserAvatarList = UserAvatarItem[];

interface UserAvatarListResponse extends ApiResponse, UserAvatarList {}

export const userAvatar = {
  get: async () => {
    return await apiClients.default.get<UserAvatarListResponse>('Avatars');
  },
};
