import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { INotificationStatus } from '../../http/models/notification-status';
import { v1 } from '@api/v1';

interface INotificationStatusState {
  loading: boolean;
  status: INotificationStatus;
  error?: string;
}

const initialState: INotificationStatusState = {
  loading: true,
  status: {
    newNotifications: 0,
    newNews: 0,
    newAchievements: 0,
  },
};

const slice = createSlice({
  name: 'notificationStatus',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStatus: (state, action: PayloadAction<INotificationStatus>) => {
      state.status = { ...state.status, ...action.payload };
    },
  },
});

const { setLoading, setStatus, setError } = slice.actions;

const notificationStatus = {
  setLoading,
  setStatus,
  setError,
  selectLoading: (state: RootState) => state.notificationStatus.loading,
  selectError: (state: RootState) => state.notificationStatus.error,
  selectStatus: (state: RootState) => state.notificationStatus.status,
  loadStatus: (): AppThunk => async (dispatch) => {
    try {
      dispatch(setError());
      const response = await v1.notification.status.get();
      if (response.errorCode) {
        dispatch(setError(response.errorMsg));
        return;
      }
      dispatch(setStatus(response ?? {}));
    } finally {
      dispatch(setLoading(false));
    }
  },
};

export const notificationStatusReducer = slice.reducer;
export default notificationStatus;
