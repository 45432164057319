import { apiClients } from '../../index';
import { EMediaType } from '../../../enums';
import { ApiResponse } from '../../../types';
import { IMediaPreviewUrls } from '../../../models/media-preview-urls';

export interface MediaItemDto {
  url?: string;
  type?: EMediaType;
  previewUrls?: IMediaPreviewUrls;
  order?: number;
}

type MediaList = MediaItemDto[];

interface MediaListResponse extends ApiResponse, MediaList {}

export const challengesInstruction = {
  get: async (id?: number) => {
    const response = await apiClients.default.get<MediaListResponse>(
      `Challenges/${id}/Instruction`,
    );
    return response;
  },
};
