import { ApiResponse, ListApiResponse } from '../../../types';
import { Paging } from '../../../../types';
import { apiClients } from '../../index';

export interface NewsItem {
  date?: string;
  eventId?: number;
  eventName?: string;
  htmlText?: string;
  id?: number;
  image?: string;
  isRead?: boolean;
  timeFromCreated?: string;
  title?: string;
}

export interface NewsListResponse extends ListApiResponse<NewsItem> {}
export interface NewsResponse extends NewsItem, ApiResponse {}

export const news = {
  get: async (request: Paging) => {
    return await apiClients.default.get<NewsListResponse>('news', { params: request });
  },
  ID: {
    get: async (id: number) => {
      return await apiClients.default.get<NewsResponse>(`news/${id}`);
    },
  },
};

export const hotnews = {
  get: async () => {
    return await apiClients.default.get<NewsItem[]>(`hotnews`);
  },
};
