import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { IEventItem } from '../../http/models/event-item';
import { v1 } from '@api/v1';

interface IEventListState {
  loading: boolean;
  items: IEventItem[];
  error?: string;
}

const initialState: IEventListState = {
  loading: true,
  items: [],
};

const slice = createSlice({
  name: 'eventList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<IEventItem[]>) => {
      state.items = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
});

const { setLoading, setItems, setError } = slice.actions;

const eventList = {
  setLoading,
  setItems,
  setError,
  selectLoading: (state: RootState) => state.eventList.loading,
  selectItems: (state: RootState) => state.eventList.items,
  selectError: (state: RootState) => state.eventList.error,
  loadData: (): AppThunk => async (dispatch) => {
    try {
      dispatch(setError());
      const response = await v1.event.get({ banners: { fetch: true } });
      if (response.errorCode) {
        dispatch(setError(response.errorMsg));
        console.log(`errorCode: ${response.errorCode}; errorMsg: ${response.errorMsg}`);
        return;
      }

      let items = response.items ?? [];
      const shop = items.find((x: any) => x.id?.toString() === '2');
      if (shop) {
        const newItems = [shop];
        for (let i = 0; i < items.length; i++) {
          if (items[i] && items[i] !== shop) {
            newItems.push(items[i]);
          }
        }
        items = newItems;
      }

      dispatch(setItems(items));
    } finally {
      dispatch(setLoading(false));
    }
  },
};

export const eventListReducer = slice.reducer;
export default eventList;
