import { apiClients } from '../../index';
import { IAchievementObjectivesItem } from '../../../models/achievement-objectives-item';
import { ApiResponse } from '../../../types';

export interface IUserAchievements {
  name: string;
  iconPath: string;
  skillTypeName: string;
  skillTypeLevelNumber: number;
  achievementObjectives: IAchievementObjectivesItem[];
}

export interface IUserUpdates {
  userAchievements?: IUserAchievements[];
  level?: number;
  levelCapabilities?: string;
}

interface IUserUpdatesResponse extends IUserUpdates, ApiResponse {}

export const userUpdates = {
  get: async (): Promise<IUserUpdatesResponse> => {
    return await apiClients.default.get<IUserUpdatesResponse>(`v1/user/updates`);
  },
  post: async (): Promise<ApiResponse> => {
    return await apiClients.default.post<ApiResponse>(`v1/user/updates`);
  },
};
