import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { SkillTypeDetails } from '../../http/api/v0_1/skill-types/skill-types';
import { IChallengeItem } from '../../http/models/challenge-item';
import { v0_1 } from '@api/v0_1';
import { v1 } from '@api/v1';

interface ISkillState {
  error?: string;
  loading?: boolean;
  data?: SkillTypeDetails;
  challenge?: {
    error?: string;
    loading?: boolean;
    items?: IChallengeItem[];
    total?: number;
  };
}

const initialState: { [p: string]: ISkillState } = {};

const slice = createSlice({
  name: 'skillDetails',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: string; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].error = action.payload.error;
    },
    setData: (state, action: PayloadAction<{ id: string; data?: SkillTypeDetails }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].data = action.payload.data;
    },
    setChallengeError: (state, action: PayloadAction<{ id: string; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].challenge = state[action.payload.id].challenge ?? {};
      state[action.payload.id].challenge!.error = action.payload.error;
    },
    setChallengeLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].challenge = state[action.payload.id].challenge ?? {};
      state[action.payload.id].challenge!.loading = action.payload.loading;
    },
    setChallengeItems: (state, action: PayloadAction<{ id: string; items?: IChallengeItem[] }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].challenge = state[action.payload.id].challenge ?? {};
      state[action.payload.id].challenge!.items = action.payload.items;
    },
    setChallengeTotal: (state, action: PayloadAction<{ id: string; total?: number }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].challenge = state[action.payload.id].challenge ?? {};
      state[action.payload.id].challenge!.total = action.payload.total;
    },
  },
});

const {
  setError,
  setLoading,
  setData,
  setChallengeError,
  setChallengeLoading,
  setChallengeItems,
  setChallengeTotal,
} = slice.actions;

const skillDetails = {
  setLoading: (id: string, loading: boolean) => setLoading({ id, loading }),
  setError: (id: string, error?: string) => setError({ id, error }),
  selectLoading: (id: string) => (state: RootState) => state.skillDetails[id]?.loading ?? true,
  selectError: (id: string) => (state: RootState) => state.skillDetails[id]?.error,
  selectData: (id: string) => (state: RootState) => state.skillDetails[id]?.data,
  loadData:
    (id: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setError({ id, error: '' }));
        const response = await v0_1.skillTypes.get(id);
        if (response.errorCode) {
          if (response.errorCode === 403) {
            window.location.href = '/restricted';
            return;
          }
          dispatch(setError({ id, error: response.errorMsg }));
          return;
        }
        dispatch(setData({ id, data: response }));
      } finally {
        dispatch(setLoading({ id, loading: false }));
      }
    },

  challengeList: {
    setError: (id: string, error?: string) => setChallengeError({ id, error }),
    setLoading: (id: string, loading: boolean) => setChallengeLoading({ id, loading }),
    selectError: (id: string) => (state: RootState) => state.skillDetails[id]?.challenge?.error,
    selectLoading: (id: string) => (state: RootState) =>
      state.skillDetails[id]?.challenge?.loading ?? true,
    selectItems: (id: string) => (state: RootState) =>
      state.skillDetails[id]?.challenge?.items ?? [],
    selectTotal: (id: string) => (state: RootState) =>
      state.skillDetails[id]?.challenge?.total ?? 0,
    loadData:
      (id: string): AppThunk =>
      async (dispatch) => {
        try {
          dispatch(setChallengeError({ id }));
          const response = await v1.challenge.published.get({
            skills: { fetch: true, id: [Number.parseInt(id)] },
            rubrics: { fetch: true },
            attributes: { fetch: true },
          });
          if (response.errorCode) {
            dispatch(setChallengeError({ id, error: response.errorMsg }));
            return;
          }
          dispatch(setChallengeItems({ id, items: response.items ?? [] }));
          dispatch(setChallengeTotal({ id, total: response.total ?? 0 }));
        } finally {
          dispatch(setChallengeLoading({ id, loading: false }));
        }
      },
  },
};

export const skillDetailsReducer = slice.reducer;
export default skillDetails;
