import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { isEmpty } from 'lodash';
import { batch } from 'react-redux';
import storage from '..';
import { IProfileUserBlocklistItem, IProfileResponse } from '@api/v1/profile/profile';
import { v1 } from '@api/v1';
import { v0_1 } from '@api/v0_1';

interface IUserProfileState {
  error?: string;
  loading: boolean;
  data: IProfileResponse;
}

const initialState: { [nickname: string]: IUserProfileState } = {};

const createState = (): IUserProfileState => ({
  data: {},
  loading: true,
});

const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ nickname: string; loading: boolean }>) => {
      state[action.payload.nickname] = state[action.payload.nickname] ?? createState();
      state[action.payload.nickname].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ nickname: string; error?: string }>) => {
      state[action.payload.nickname] = state[action.payload.nickname] ?? createState();
      state[action.payload.nickname].error = action.payload.error;
    },
    setData: (state, action: PayloadAction<{ nickname: string; data: IProfileResponse }>) => {
      state[action.payload.nickname] = state[action.payload.nickname] ?? createState();
      state[action.payload.nickname].data = action.payload.data;
    },
    toggleIsUserInBlocklist: (state, action: PayloadAction<string>) => {
      if (!isEmpty(state[action.payload])) {
        state[action.payload].data.inBlocklist = !state[action.payload].data.inBlocklist;
      }
    },
    toggleUserSubscribe: (state, action: PayloadAction<string>) => {
      if (!isEmpty(state[action.payload])) {
        state[action.payload].data.subscription = !state[action.payload].data.subscription;
      }
    },
  },
});

const { setLoading, setError, setData, toggleIsUserInBlocklist, toggleUserSubscribe } =
  slice.actions;

const userProfile = {
  setLoading: (nickname: string, loading: boolean) => setLoading({ nickname, loading }),
  setError: (nickname: string, error?: string) => setError({ nickname, error }),
  selectLoading: (nickname: string) => (state: RootState) =>
    state.userProfile[nickname]?.loading ?? true,
  selectError: (nickname: string) => (state: RootState) => state.userProfile[nickname]?.error,
  selectData: (nickname: string) => (state: RootState) => state.userProfile[nickname]?.data ?? {},
  loadData:
    (nickname: string): AppThunk =>
    async (dispatch, getState) => {
      const isCurrentUser = nickname === getState().identity.nickname;
      dispatch(setError({ nickname, error: undefined }));

      try {
        const response: IProfileResponse = isCurrentUser
          ? await v1.profile.get()
          : await v1.user.NICKNAME.get(nickname);

        if (response.errorCode) {
          dispatch(setError({ nickname, error: response.errorMsg }));
          return;
        }

        response.isCurrentUser = isCurrentUser;
        dispatch(setData({ nickname, data: response }));
      } finally {
        dispatch(setLoading({ nickname, loading: false }));
      }
    },
  toggleBlockUserById:
    (nickname: string, id: string): AppThunk =>
    async (dispatch, getState) => {
      try {
        const response = getState().userProfile[nickname].data.inBlocklist
          ? await v1.user.blocklist.delete(id)
          : await v1.user.blocklist.post(id);

        if (response.errorCode) {
          console.error(response.errorMsg);
          return;
        }
      } finally {
        dispatch(toggleIsUserInBlocklist(nickname));
      }
    },
  removeFromBlocklistUserById:
    (item: IProfileUserBlocklistItem): AppThunk =>
    async (dispatch) => {
      try {
        const response = await v1.user.blocklist.delete(item.id);
        if (response.errorCode) {
          console.error(response.errorMsg);
          return;
        }
      } finally {
        batch(() => {
          dispatch(toggleIsUserInBlocklist(item.nickName));
          dispatch(storage.profile.removeUserFromBlocklist(item.nickName));
        });
      }
    },
  toggleSubscribeToUser:
    (nickname: string, id: string): AppThunk =>
    async (dispatch, getState) => {
      try {
        const response = getState().userProfile[nickname].data.subscription
          ? await v0_1.following.delete(id)
          : await v0_1.following.post(id);

        if (response.errorCode) {
          console.error(response.errorMsg);
          return;
        }
      } finally {
        dispatch(toggleUserSubscribe(nickname));
      }
    },
};

export const userProfileReducer = slice.reducer;
export default userProfile;
