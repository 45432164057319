import { apiClients } from '../../';
import { ApiResponse } from '../../../types';

interface IdentityExternalResponse extends ApiResponse {
  access_token?: string;
  refresh_token?: string;
}

interface RegistrationRequest {
  token: string;
  referralCode: string;
  userName: string;
  password: string;
}

export const identityExternal = {
  authorization: {
    post: (token: string) => {
      return apiClients.default.post<IdentityExternalResponse>(
        'v1/identity/external/authorization',
        { token },
      );
    },
  },
  registration: {
    post: (request: RegistrationRequest) => {
      return apiClients.default.post<ApiResponse>('v1/identity/external/registration', request);
    },
  },
};
