import { apiClients } from '../../';

interface ILoggerRequest {
  level: string
  message: string
}

export const logger = {
  post: (request: ILoggerRequest) => {
    return apiClients.default.post('v1/logger', request);
  },
};
