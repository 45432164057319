import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { mergeWith, keyBy } from 'lodash';
import { IProjectItem } from '../../http/models/project-item';
import { batch } from 'react-redux';
import { v1 } from '@api/v1';
import i18next from 'i18next';

interface IUserProjectListState {
  error?: string;
  loading: boolean;
  items: IProjectItem[];
  total: number;
  page: number;
}

const initialState: { [userId: string]: IUserProjectListState } = {};

const getUserState = (state: typeof initialState, userId: string): IUserProjectListState => {
  if (!state[userId]) {
    state[userId] = { loading: true, items: [], total: 0, page: 1 };
  }
  return state[userId];
};

const slice = createSlice({
  name: 'userProjectList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ userId: string; loading: boolean }>) => {
      const userState = getUserState(state, action.payload.userId);
      userState.loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ userId: string; error?: string }>) => {
      const userState = getUserState(state, action.payload.userId);
      userState.error = action.payload.error;
    },
    setItems: (state, action: PayloadAction<{ userId: string; items: IProjectItem[] }>) => {
      const userState = getUserState(state, action.payload.userId);
      userState.items = action.payload.items;
    },
    addItems: (state, action: PayloadAction<{ userId: string; items: IProjectItem[] }>) => {
      const userState = getUserState(state, action.payload.userId);
      const newItems = action.payload.items;

      // Создаем объект новых элементов по id для легкого доступа
      const newItemsMap = keyBy(newItems, 'id');

      // Обновляем существующие элементы, сохраняя их порядок
      userState.items = userState.items.map((existingItem) => {
        if (newItemsMap[existingItem.id]) {
          // Заменяем существующий элемент на новый
          return newItemsMap[existingItem.id];
        }
        return existingItem;
      });

      // Добавляем новые элементы, которых нет в текущем состоянии
      const existingItemIds = new Set(userState.items.map((item) => item.id));
      const additionalNewItems = newItems.filter((item) => !existingItemIds.has(item.id));

      userState.items.push(...additionalNewItems);
    },
    setTotal: (state, action: PayloadAction<{ userId: string; total: number }>) => {
      const userState = getUserState(state, action.payload.userId);
      userState.total = action.payload.total;
    },
    setPage: (state, action: PayloadAction<{ userId: string; page: number }>) => {
      const userState = getUserState(state, action.payload.userId);
      userState.page = action.payload.page;
    },
    setImageSize: (
      state,
      action: PayloadAction<{ userId: string; projectId: number; width: number; height: number }>,
    ) => {
      const userState = getUserState(state, action.payload.userId);
      const project = userState.items.find((item) => item.id === action.payload.projectId);
      if (project) {
        project.previewImageWidth = action.payload.width;
        project.previewImageHeight = action.payload.height;
      }
    },
    reset: () => initialState,
  },
});

const { setLoading, setError, addItems, setTotal, setPage, setItems } = slice.actions;

const loadItems =
  ({ userId, loadMore }: { userId: string; loadMore?: boolean }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const userState = state.userProjectList[userId] ?? { page: 1 };
    dispatch(setError({ userId, error: undefined }));

    try {
      const response = await v1.project.get({
        author: { id: [userId] },
        medias: { fetch: true },
        page: loadMore ? userState.page + 1 : 1,
        take: 20,
      });

      if (response.errorCode) {
        const errorMessage =
          response.errorCode === 500 ? i18next.t('common__msg_error_request') : response.errorMsg;
        dispatch(setError({ userId, error: errorMessage }));
        return;
      }

      batch(() => {
        dispatch(
          loadMore
            ? addItems({ userId, items: response.items || [] })
            : setItems({ userId, items: response.items || [] }),
        );
        dispatch(setPage({ userId, page: loadMore ? userState.page + 1 : 1 }));
        dispatch(setTotal({ userId, total: response.total ?? 0 }));
      });
    } finally {
      dispatch(setLoading({ userId, loading: false }));
    }
  };

const selectLoading = (userId: string) => (state: RootState) =>
  state.userProjectList[userId]?.loading ?? false;
const selectError = (userId: string) => (state: RootState) => state.userProjectList[userId]?.error;
const selectItems = (userId: string) => (state: RootState) =>
  state.userProjectList[userId]?.items ?? [];
const selectTotal = (userId: string) => (state: RootState) =>
  state.userProjectList[userId]?.total ?? 0;

const userProjectList = {
  ...slice.actions,
  loadItems,
  selectLoading,
  selectError,
  selectItems,
  selectTotal,
};

export const userProjectListReducer = slice.reducer;
export default userProjectList;
