import { apiClients } from '../../index';
import { ApiResponse } from '../../../types';

export const subscribe = {
  post: (id: number) =>
    apiClients.default.post<ApiResponse>(`v1/event/subscribe`, null, {
      params: { eventId: id },
    }),
  delete: (id: number) =>
    apiClients.default.delete<ApiResponse>(`v1/event/subscribe`, {
      params: { eventId: id },
    }),
};
