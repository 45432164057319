import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { UserAvatarItem } from '../../http/api/v0_1/user/user-avatar';
import { v0_1 } from '@api/v0_1';

interface IAvatarListState {
  error?: string;
  loading: boolean;
  items: UserAvatarItem[];
  total: number;
}

const initialState: IAvatarListState = {
  loading: true,
  items: [],
  total: 0,
};

const slice = createSlice({
  name: 'avatarList',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<UserAvatarItem[]>) => {
      state.items = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
  },
});

const { setError, setLoading, setItems, setTotal } = slice.actions;

const avatarList = {
  setError,
  setLoading,
  selectError: (state: RootState) => state.avatarList.error,
  selectLoading: (state: RootState) => state.avatarList.loading,
  selectItems: (state: RootState) => state.avatarList.items,
  selectTotal: (state: RootState) => state.avatarList.total,
  loadData: (): AppThunk => async (dispatch) => {
    try {
      dispatch(setError());
      const response = await v0_1.user.avatar.get();
      if (response.errorCode) {
        dispatch(setError(response.errorMsg));
        console.log(`errorCode: ${response.errorCode}; errorMsg: ${response.errorMsg}`);
        dispatch(setLoading(false));

        return;
      }
      if (response.length) {
        dispatch(setItems(response));
        dispatch(setTotal(response.length));
        dispatch(setLoading(false));
      }
    } finally {
      dispatch(setLoading(false));
    }
  },
};

export const avatarListReducer = slice.reducer;
export default avatarList;
