import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { EEventUserRatingType } from '../../http/enums';
import { IEventRatingResponse } from '../../http/api/v1/event/event-rating';
import { v1 } from '@api/v1';

interface IEventRatingState {
  loading: boolean;
  error?: string;
  data: IEventRatingResponse;
  type: EEventUserRatingType;
}

const initialState: { [p: string]: IEventRatingState } = {};

const createState = (): IEventRatingState => ({
  loading: false,
  data: {},
  type: EEventUserRatingType.Default,
});

const slice = createSlice({
  name: 'eventRating',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ id: number; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].error = action.payload.error;
    },
    setData: (state, action: PayloadAction<{ id: number; data?: IEventRatingResponse }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].data = action.payload.data ?? {};
    },
    setType: (state, action: PayloadAction<{ id: number; type: EEventUserRatingType }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].type = action.payload.type;
    },
  },
});

const { setLoading, setError, setData, setType } = slice.actions;

const eventRating = {
  setLoading: (id: number, loading: boolean) => setLoading({ id, loading }),
  setError: (id: number, error?: string) => setError({ id, error }),
  selectError: (id: number) => (state: RootState) => state.eventRating[id]?.error,
  selectLoading: (id: number) => (state: RootState) => state.eventRating[id]?.loading ?? true,
  selectData: (id: number) => (state: RootState) => state.eventRating[id]?.data ?? {},
  selectAllData: () => (state: RootState) => state.eventRating ?? {},
  selectCurrentUserRating: (id: number) => (state: RootState) =>
    state.eventRating[id]?.data.currentUserRating,
  selectItems: (id: number, type: EEventUserRatingType) => (state: RootState) => {
    switch (type) {
      case EEventUserRatingType.Subdivision:
        return state.eventRating[id]?.data.subdivisionUserRatings;
      case EEventUserRatingType.Location:
        return state.eventRating[id]?.data.locationUserRatings;
      case EEventUserRatingType.Top100:
        return state.eventRating[id]?.data.top100UserRatings;
      case EEventUserRatingType.General:
        return state.eventRating[id]?.data.generalUserRatings;
      default:
        return [];
    }
  },
  loadData:
    (id: number, type?: EEventUserRatingType, take?: number): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setLoading({ id, loading: true }));
        type = type ?? EEventUserRatingType.Default;
        dispatch(setError({ id }));
        dispatch(setType({ id, type }));
        const response = await v1.event.id.rating.get(id, {
          userRatingType: type,
          take: take ?? (type === EEventUserRatingType.Top100 ? 100 : 500),
        });
        if (response.errorCode) {
          if (response.errorCode === 500) {
            // todo убрать после того как вольем в коробку
            dispatch(setError({ id, error: 'Ошибка сервера, повторите запрос позже' }));
          } else {
            dispatch(setError({ id, error: response.errorMsg }));
          }
          return;
        }
        dispatch(setData({ id, data: response }));
      } finally {
        dispatch(setLoading({ id, loading: false }));
      }
    },
};

export const eventRatingReducer = slice.reducer;
export default eventRating;
