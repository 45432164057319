import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IRubricItem } from '@http/models/rubric-item';
import { ELoadingStatus } from '@http/enums';
import { v1 } from '@http/api/v1';
import { v0_1 } from '@http/api/v0_1';
import { ResponseGetCurrentUserRubrics } from '../../http/api/v0_1/rubrics/rubrics';
import { ApiErrorResponseV01 } from '../../http/types';
import { isEmpty } from 'lodash';

// Определяем интерфейс состояния слайса
interface RubricsListState {
  error?: string;
  loadingStatus: ELoadingStatus;
  loadingSelectedRubricStatus: ELoadingStatus;
  items: IRubricItem[];
  selectedItems: IRubricItem[];
}

// Начальное состояние
const initialState: RubricsListState = {
  loadingStatus: ELoadingStatus.Idle,
  loadingSelectedRubricStatus: ELoadingStatus.Idle,
  items: [],
  selectedItems: [],
};

// Асинхронное действие для загрузки рубрик
export const loadRubrics = createAsyncThunk(
  'rubricsList/loadRubrics',
  async (_, { rejectWithValue }) => {
    try {
      const response = await v1.rubrics.get();
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return response.items ?? [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Асинхронное действие для обновления выбранных рубрик
export const updateSelectedRubrics = createAsyncThunk(
  'rubricsList/updateSelectedRubrics',
  async (rubricList: IRubricItem[], { rejectWithValue }) => {
    try {
      const rubricIds = rubricList.map((item) => item.id);
      const response = await v0_1.rubrics.post(rubricIds);
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return rubricList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Асинхронное действие для загрузки выбранных рубрик текущего пользователя
export const loadDataSelectedRubric = createAsyncThunk(
  'rubricsList/loadDataSelectedRubric',
  async (_, { rejectWithValue }) => {
    try {
      const response: ResponseGetCurrentUserRubrics = await v0_1.rubrics.currentUserRubrics.get();

      // Проверка на наличие ошибок в ответе
      if (!Array.isArray(response)) {
        const errorResponse = response as ApiErrorResponseV01;

        // Если в ответе есть ошибки
        if (errorResponse.errors && !isEmpty(errorResponse.errors)) {
          const firstErrorKey = Object.keys(errorResponse.errors)[0];
          const firstErrorMessage =
            (errorResponse.errors as Record<string, string[]>)[firstErrorKey]?.[0] ||
            'Неизвестная ошибка';
          return rejectWithValue(firstErrorMessage);
        }

        // Обработка других ошибок без поля `errors`
        return rejectWithValue(errorResponse.detail || 'Ошибка загрузки рубрик');
      }

      // Возвращаем успешный ответ (массив рубрик)
      return response;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

// Создаем slice
const rubricsSlice = createSlice({
  name: 'rubricsList',
  initialState,
  reducers: {
    setSelectedItems: (state, action: PayloadAction<IRubricItem[]>) => {
      state.selectedItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadRubrics.pending, (state) => {
        state.loadingStatus = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadRubrics.fulfilled, (state, action: PayloadAction<IRubricItem[]>) => {
        state.items = action.payload;
        state.loadingStatus = ELoadingStatus.Succeeded;
      })
      .addCase(loadRubrics.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingStatus = ELoadingStatus.Failed;
      })
      .addCase(updateSelectedRubrics.pending, (state) => {
        state.loadingStatus = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(updateSelectedRubrics.fulfilled, (state, action: PayloadAction<IRubricItem[]>) => {
        state.selectedItems = action.payload;
        state.loadingStatus = ELoadingStatus.Succeeded;
      })
      .addCase(updateSelectedRubrics.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingStatus = ELoadingStatus.Failed;
      })
      //
      .addCase(loadDataSelectedRubric.pending, (state) => {
        state.loadingSelectedRubricStatus = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadDataSelectedRubric.fulfilled, (state, action: PayloadAction<IRubricItem[]>) => {
        state.selectedItems = action.payload;
        state.loadingSelectedRubricStatus = ELoadingStatus.Succeeded;
      })
      .addCase(loadDataSelectedRubric.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingSelectedRubricStatus = ELoadingStatus.Failed;
      });
  },
});

const rubricsList = {
  ...rubricsSlice.actions,
};

export const rubricsListReducer = rubricsSlice.reducer;
export default rubricsList;

// Селекторы для получения частей состояния из store
export const selectRubricsLoadingStatus = (state: RootState) => state.rubricsList.loadingStatus;
export const selectRubricsItems = (state: RootState) => state.rubricsList.items;
export const selectSelectedRubrics = (state: RootState) => state.rubricsList.selectedItems;
export const selectRubricsError = (state: RootState) => state.rubricsList.error;
